var _ = require('lodash');

const ctrl = ['$scope', '$element', function ($scope, $element) {
    const my = this;

    function getDraftVarName(doc) {
        return doc.entityId ? `${doc.docType}@${doc.entityId}` : doc.docType;
    };

    my.isDocInvalid = function (doc) {
        const draftVarName = getDraftVarName(doc);
        const isNotUploaded = doc.canUpload &&
            doc.isRequired &&
            (!doc.files || !doc.files.filter(f => !f.toBeDeleted).length) &&
            (!$scope.drafts || !$scope.drafts[draftVarName] || !$scope.drafts[draftVarName].length);
        const isNotSigned = $scope.canSign && !my.isFilesSigned(doc);
        return isNotUploaded || isNotSigned;
    };

    my.docDraft = function (doc) {
        const draftVarName = getDraftVarName(doc);
        if (!$scope.drafts) $scope.drafts = {};
        if (!$scope.drafts[draftVarName]) $scope.drafts[draftVarName] = []
        return $scope.drafts[draftVarName];
    };

    my.onChangeHandler = function (doc, drafts) {
        if ($scope.drafts) {
            const draftVarName = getDraftVarName(doc);
            if (drafts && drafts.length) {
                $scope.drafts[draftVarName] = drafts;
                $scope.hasChange = true;
            } else {
                $scope.drafts[draftVarName] = [];
                $scope.hasChange = false;
            }
        }
    };

    my.asArray = function (docs) {
        return Object.values(docs || {});
    };

    my.isEntitiesSigned = function () {
        return _.every($scope.model.entities, x => my.isDocsSigned(x));
    }

    my.isDocsSigned = function (entity) {
        const docs = my.asArray(entity.docs);
        const result = _.every(docs, x => my.isFilesSigned(x));
        return result;
    }

    my.isFilesSigned = function (doc) {
        const drafts = my.docDraft(doc) || [];
        const draftsNames = drafts.reduce((map, file) => {
            map[file.name] = true;
            return map;
        }, {});
        const result = _.every(doc.files || [], x => {
            return my.fileIsSigned(x) || draftsNames[x.name + '.sig']
        });
        return result;
    }

    my.fileIsSigned = function (file) {
        return file.signed || !!file.signInfo;
    }
}];

export default ctrl;
