import selectLotTmpl from './select-lot/template.html'
import selectLotCtrl from './select-lot/controller'
import { calcDuration, mapServiceLotToModel, today } from './utils'

const ctrl = ['$scope', 'fzModal', '$http', function ($scope, fzModal, $http) {
    
  const my = this
    
  $scope.model.maxDuration = null
    
  my.state = {
    isZgrSearching: false,
    searchResult: null,
    zgrNotFound: false,
    isManualPurchase: false,
    showEditDetails: false,
  }

  $scope.$watch('model', function () {
    // reset
    my.state = {
      isZgrSearching: false,
      searchResult: null,
      zgrNotFound: false,
      isManualPurchase: false,
      showEditDetails: false,
    }
  })

  my.filterLaw = item => !($scope.model.data.bankGuaranteeTypeRefId === 'participation' && item.code === '185-615')

  my.filterBankGuaranteeType = item => !((((($scope.model || {}).data || {}).purchase || {}).law || '') === '185-615' && item.code === 'participation')

  const search = (purchaseNumber) => $http.post('/api/zgr/search?purchaseNumber=' + purchaseNumber).then(resp => resp.data)

  my.zgrSearch = () => {
    my.state.isManualPurchase = false
    my.state.isZgrSearching = true
    my.state.searchResult = null

    search($scope.model.data.purchase.purchaseNumber).then(resp => {
      if (!resp?.result?.lots || !resp?.result?.lots?.length) {
        my.state.zgrNotFound = true
      } else {
        my.state.zgrNotFound = false
        my.state.searchResult = resp.result
        my.state.showEditDetails = true
        my.state.isJointProcurement = true
        $scope.model.data.purchase.marketPlace = resp.result.etp.name
        $scope.model.data.purchase.law = resp.result.federalLawRefId
        $scope.model.data.purchase.tenderType = resp.result.placingWay
        $scope.model.data.purchase.tenderDate = resp.result.publishDate
        $scope.model.data.purchase.purchaseLink = resp.result.url
        $scope.model.data.purchase.purchaseSubject = resp.result.subject
        if (resp.result.lots.length > 0) {
          var lotNumbers = 0
          var lots = []
          resp.result.lots.forEach(lot => {
            var lotNumber = 0
            const { number, products, customers, subject, currency, ikzList, maxAmount } = lot
            if (customers.length > 0) {
              var startAmount = customers.length === 1 ? maxAmount : null
              customers.forEach((item, index) => {
                lotNumber++
                lotNumbers++
                lots.push({
                  products,
                  subject,
                  currency,
                  number: number + '.' + lotNumber,
                  maxAmount: startAmount,
                  customer: item,
                  customers: [item],
                  ikzList: [ikzList[index]],
                })
              })
            }
          })
          $scope.model.data.purchase = {
            ...$scope.model.data.purchase,
            multiLot: lotNumbers > 1,
            lots,
          }
          my.state.searchResult = {
            ...resp.result,
            multiLot: lotNumbers > 1,
            lots,
          }
          if (lotNumbers === 1) {
            $scope.model.data.purchase.lots = resp.result.lots.map(lot => mapServiceLotToModel(lot, $scope.model.data.bankGuaranteeTypeRefId))
          } else {
            my.selectLot()
          }
        }
        if ($scope.model.data.bankGuaranteeTypeRefId === 'participation' && resp.result.federalLawRefId === '185-615') {
          $scope.model.data.bankGuaranteeTypeRefId = null
        }
      }
      my.state.isZgrSearching = false
    }).catch(err => {
      my.state.zgrNotFound = true
      my.state.isZgrSearching = false
    })
  }

  my.setManualPurchase = () => {
    my.state.searchResult = null
    my.state.isManualPurchase = true
    my.state.showEditDetails = true
    my.state.zgrNotFound = false
    $scope.model.data.purchase = {
      purchaseNumber: $scope.model.data.purchase.purchaseNumber
    }
  }

  my.editPurchase = () => {
    my.state.searchResult = null
    my.state.isManualPurchase = false
    my.state.showEditDetails = false
    my.state.zgrNotFound = false
    $scope.model.data.purchase = {
      purchaseNumber: $scope.model.data.purchase.purchaseNumber
    }
  }

  my.addProduct = (lot) => lot.products.push({})

  my.removeProduct = (lot, prod) => {
    const index = lot.products.indexOf(prod)
    if (index === -1) return
    lot.products.splice(index, 1)
  }

  my.addIkz = (lot) => lot.ikzList.push('')

  my.removeIkz = (lot, index) => lot.ikzList.splice(index, 1)

  my.selectLot = () => {
    fzModal.open({
      windowClass: 'fz-modal__window-lots',
      template: selectLotTmpl,
      controller: selectLotCtrl,
      controllerAs: 'my',
      scope: {
        modalTitle: 'Выберите лоты'
      },
      params: {
        lots: my.state.searchResult.lots
      }
    }).closed.then(result => {
      if (result.resultCode != 'OK') return
      if (!result.selectedLots || !result.selectedLots.length) {
        $scope.model.data.purchase.lots = []
      } else {
        $scope.model.data.purchase.lots = result.selectedLots.map(lot => mapServiceLotToModel(lot, $scope.model.data.bankGuaranteeTypeRefId))
      }
    })
  }

  // Калькулируемое поле - %% снижения от начальной цены контракта
  my.calcDeltaPrice = function calcDeltaPrice(lot) {
    let deltaPrice = null
    if (!lot.startAmount || lot.startAmount == 0) {
      deltaPrice = null
    } else if (!lot.finalAmount || lot.finalAmount == 0) {
      deltaPrice = null
    } else {
      deltaPrice = Math.round(((lot.startAmount - lot.finalAmount) / lot.startAmount * 100) * 100) / 100
    }
    return deltaPrice
  }

  // Гарантийный период
  my.showIsRequiredSecurityForGuaranteePeriod = () => $scope.model.config
    ? $scope.model.config.guaranteePeriodCheck.show && $scope.model.data.bankGuaranteeTypeRefId === 'execution'
    : $scope.model.data.bankGuaranteeTypeRefId === 'execution' 
  
  // Бесспорное списание
  my.showIsRequiredIndisputableDebiting = () => $scope.model.config
    ? $scope.model.config.indisputableDebitingCheck.show && ['44', '223', '615'].includes($scope.model.data.purchase.law)
    : ['44', '223', '615'].includes($scope.model.data.purchase.law) 
  
  // Неустойки
  my.showIsIncludedForfeit = () => $scope.model.config
    ? $scope.model.config.forfeitCheck.show && $scope.model.data.bankGuaranteeTypeRefId === 'execution'
    : $scope.model.data.bankGuaranteeTypeRefId === 'execution' 
  
  // Aванс
  my.showPrepaymentExists = () => $scope.model.config
    ? $scope.model.config.prepaymentCheck.show && $scope.model.data.bankGuaranteeTypeRefId === 'execution'
    : $scope.model.data.bankGuaranteeTypeRefId === 'execution'

  my.showContractConditions = () =>
    my.showIsRequiredSecurityForGuaranteePeriod() ||
    my.showIsRequiredIndisputableDebiting() ||
    my.showIsIncludedForfeit() ||
    my.showPrepaymentExists()

  // Обязательность Идентификационный код закупки
  my.isRequiredLotIkzList = () =>
    $scope.model.config &&
    $scope.model.config.lotIkzListEnabled &&
    $scope.model.config.lotIkzListEnabled.required

  my.calcDuration = (startDate, endDate) => {
    let days = calcDuration(startDate, endDate)
    return !days ? null : days
  }
  my.validateDuration = (startDate, endDate) => {
    if (!$scope.model.maxDuration) {
      return true
    }
    let days = calcDuration(startDate, endDate)
    return !(!days || days > $scope.model.maxDuration)
  }

  my.today = () => today()

  my.calcLotConAmount = lot => (lot.beneficiaries || [])
    .map(({ conAmount }) => conAmount)
    .reduce((a, b) => a + b, 0)

  my.calcLotBgAmount = lot => (lot.beneficiaries || [])
    .map(({ bgAmount }) => bgAmount)
    .reduce((a, b) => a + b, 0)

  my.calcFullBgAmount = () => (((($scope.model || {}).data || {}).purchase || {}).lots || [])
    .map(lot => my.calcLotBgAmount(lot))
    .reduce((a, b) => a + b, 0)
  
  // PML-123
  my.increaseAmount = (lot, x) => {
    const i = lot.beneficiaries.indexOf(x)
    lot.beneficiaries[i].bgAmount = x.bgAmount * 1.5
  }

  my.addCustomer = lot => {
    if (!lot.beneficiaries) lot.beneficiaries = []
    lot.beneficiaries.push({})
  }

  my.removeCustomer = (lot, x) => {
    const i = lot.beneficiaries.indexOf(x)
    lot.beneficiaries.splice(i, 1)
  }

  my.addLot = () => {
    if (!$scope.model.data.purchase.lots) $scope.model.data.purchase.lots = []
    $scope.model.data.purchase.lots.push({
      ikzList: [''],
      products: [{}],
    })
  }

  my.removeLot = lot => {
    const i = $scope.model.data.purchase.lots.indexOf(lot)
    $scope.model.data.purchase.lots.splice(i, 1)
  }

  /**
   * 44-ФЗ. Статья 37. Антидемпинговые меры при проведении конкурса и аукциона
   * 615-ФЗ. Статья 90.
   */
  const isAntidumping = (lot) =>
    $scope.model.data.purchase.law == '615' ||
    (
      $scope.model.data.purchase.law == '44' &&
      $scope.model.data.bankGuaranteeTypeRefId == 'execution' &&
      lot.startAmount > 1500000000 // 15 млн. рублей
    )

  // снижение на 25 и более процентов от НМЦ
  const isBigDecrease = (lot) =>
    lot.finalAmount !== null &&
    !!lot.startAmount &&
    lot.finalAmount / lot.startAmount <= 0.75

  my.validateAntidumpingAmount = (lot) => {
    if (!isAntidumping(lot) || !isBigDecrease(lot)) return true
    if (lot.beneficiaries.some(({ conAmount }) => conAmount === null)) return true
    const conAmount = my.calcLotConAmount(lot)
    const guaranteeAmount = conAmount * 1.5
    const actualLotBgAmount = my.calcLotBgAmount(lot)
    const amountOk = actualLotBgAmount >= guaranteeAmount
    const prepaymentOk = !lot.contractConditions.prepaymentExists || actualLotBgAmount >= lot.contractConditions.prepaymentAmount
    return amountOk && prepaymentOk
  }

  /*
    bankGuaranteeTypeRefId:
    1 - Обеспечение исполнения обязательств по контракту (execution)
    2 - Обеспечение заявки на участие в торгах (participation)
    3 - Обеспечение возврата аванса (refund)
    4 - Гарантийный период (commitment)
  */

  $scope.$watch('model.data.bankGuaranteeTypeRefId', (newType) => {
    if ($scope.ngReadonly) return

    var maxDuration = null
    if ($scope.model.data && $scope.model.data.bankGuaranteeTypeRefId) {
      switch ($scope.model.data.bankGuaranteeTypeRefId) {
        case 'participation':
          maxDuration = 1950
          break
        case 'execution':
          maxDuration = 1950
          break
        case 'returnAdvance':
          maxDuration = 1080
          break
        case 'period':
          maxDuration = 1950
          break
      }
    }
    $scope.model.maxDuration = maxDuration;

    // Пересчитываем сумму гарантии при изменении вида гарантии, но только если её не правили руками
    (((($scope.model || {}).data || {}).purchase || {}).lots || []).forEach(lot => {
      (lot.beneficiaries || []).forEach(ben => {
        switch (newType) {
          case 'execution': {
            if (ben.conAmount && !ben.bgAmount && (!ben.appAmount || ben.bgAmount === ben.appAmount)) {
              ben.bgAmount = ben.conAmount
            }
            break
          }
          case 'participation': {
            if (ben.appAmount && !ben.bgAmount && (!ben.conAmount || ben.bgAmount === ben.conAmount)) {
              ben.bgAmount = ben.appAmount
            }
            break
          }
        }
      })
    })
  })
}]

export default ctrl